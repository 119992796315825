.label {
	display: inline;
	padding: .2em .6em .3em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em
}

a.label:focus,a.label:hover {
	color: #fff;
	text-decoration: none;
	cursor: pointer
}

.label:empty {
	display: none
}

.btn .label {
	position: relative;
	top: -1px
}

.label-default {
	background-color: #777
}

.label-default[href]:focus,.label-default[href]:hover {
	background-color: #5e5e5e
}

.label-primary {
	background-color: #337ab7
}

.label-primary[href]:focus,.label-primary[href]:hover {
	background-color: #286090
}

.label-success {
	background-color: #5cb85c
}

.label-success[href]:focus,.label-success[href]:hover {
	background-color: #449d44
}

.label-info {
	background-color: #5bc0de
}

.label-info[href]:focus,.label-info[href]:hover {
	background-color: #31b0d5
}

.label-warning {
	background-color: #f0ad4e
}

.label-warning[href]:focus,.label-warning[href]:hover {
	background-color: #ec971f
}

.label-danger {
	background-color: #d9534f
}

.label-danger[href]:focus,.label-danger[href]:hover {
	background-color: #c9302c
}

.badge {
	display: inline-block;
	min-width: 10px;
	padding: 3px 7px;
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color: #777;
	border-radius: 10px
}

.badge:empty {
	display: none
}

.btn .badge {
	position: relative;
	top: -1px
}

.btn-group-xs>.btn .badge,.btn-xs .badge {
	top: 0;
	padding: 1px 5px
}

a.badge:focus,a.badge:hover {
	color: #fff;
	text-decoration: none;
	cursor: pointer
}

.list-group-item.active>.badge,.nav-pills>.active>a>.badge {
	color: #337ab7;
	background-color: #fff
}

.list-group-item>.badge {
	float: right
}

.list-group-item>.badge+.badge {
	margin-right: 5px
}

.nav-pills>li>a>.badge {
	margin-left: 3px
}

.jumbotron {
	padding-top: 30px;
	padding-bottom: 30px;
	margin-bottom: 30px;
	color: inherit;
	background-color: #eee
}

.jumbotron .h1,.jumbotron h1 {
	color: inherit
}

.jumbotron p {
	margin-bottom: 15px;
	font-size: 21px;
	font-weight: 200
}

.jumbotron>hr {
	border-top-color: #d5d5d5
}

.container .jumbotron,.container-fluid .jumbotron {
	padding-right: 15px;
	padding-left: 15px;
	border-radius: 6px
}

.jumbotron .container {
	max-width: 100%
}

@media screen and (min-width:768px) {
	.jumbotron {
		padding-top: 48px;
		padding-bottom: 48px
	}

	.container .jumbotron,.container-fluid .jumbotron {
		padding-right: 60px;
		padding-left: 60px
	}

	.jumbotron .h1,.jumbotron h1 {
		font-size: 63px
	}
}

.thumbnail {
	display: block;
	padding: 4px;
	margin-bottom: 20px;
	line-height: 1.42857143;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-transition: border .2s ease-in-out;
	-o-transition: border .2s ease-in-out;
	transition: border .2s ease-in-out
}

.thumbnail a>img,.thumbnail>img {
	margin-right: auto;
	margin-left: auto
}

a.thumbnail.active,a.thumbnail:focus,a.thumbnail:hover {
	border-color: #337ab7
}

.dropdown-toggle.btn-default {
	color: #333;
	background-color: #e6e6e6;
	border-color: #adadad
}

.btn-default.active.focus,.btn-default.active:focus,.btn-default.active:hover,.btn-default:active.focus,.btn-default:active:focus,.btn-default:active:hover,.open>.dropdown-toggle.btn-default.focus,.open>.dropdown-toggle.btn-default:focus,.open>.dropdown-toggle.btn-default:hover {
	color: #333;
	background-color: #d4d4d4;
	border-color: #8c8c8c
}

.btn-default.active,.btn-default:active,.open>.dropdown-toggle.btn-default {
	background-image: none
}

.btn-default.disabled.focus,.btn-default.disabled:focus,.btn-default.disabled:hover,.btn-default[disabled].focus,.btn-default[disabled]:focus,.btn-default[disabled]:hover,fieldset[disabled] .btn-default.focus,fieldset[disabled] .btn-default:focus,fieldset[disabled] .btn-default:hover {
	background-color: #fff;
	border-color: #ccc
}

.btn-default .badge {
	color: #fff;
	background-color: #333
}

.btn-primary {
	color: #fff;
	background-color: #337ab7;
	border-color: #2e6da4
}

.btn-primary.focus,.btn-primary:focus {
	color: #fff;
	background-color: #286090;
	border-color: #122b40
}

.btn-primary:hover {
	color: #fff;
	background-color: #286090;
	border-color: #204d74
}

.btn-primary.active,.btn-primary:active,.open>.dropdown-toggle.btn-primary {
	color: #fff;
	background-color: #286090;
	border-color: #204d74
}

.btn-primary.active.focus,.btn-primary.active:focus,.btn-primary.active:hover,.btn-primary:active.focus,.btn-primary:active:focus,.btn-primary:active:hover,.open>.dropdown-toggle.btn-primary.focus,.open>.dropdown-toggle.btn-primary:focus,.open>.dropdown-toggle.btn-primary:hover {
	color: #fff;
	background-color: #204d74;
	border-color: #122b40
}

.btn-primary.active,.btn-primary:active,.open>.dropdown-toggle.btn-primary {
	background-image: none
}

.btn-primary.disabled.focus,.btn-primary.disabled:focus,.btn-primary.disabled:hover,.btn-primary[disabled].focus,.btn-primary[disabled]:focus,.btn-primary[disabled]:hover,fieldset[disabled] .btn-primary.focus,fieldset[disabled] .btn-primary:focus,fieldset[disabled] .btn-primary:hover {
	background-color: #337ab7;
	border-color: #2e6da4
}

.btn-primary .badge {
	color: #337ab7;
	background-color: #fff
}

.btn-success {
	color: #fff;
	background-color: #5cb85c;
	border-color: #4cae4c
}

.btn-success.focus,.btn-success:focus {
	color: #fff;
	background-color: #449d44;
	border-color: #255625
}

.btn-success:hover {
	color: #fff;
	background-color: #449d44;
	border-color: #398439
}

.btn-success.active,.btn-success:active,.open>.dropdown-toggle.btn-success {
	color: #fff;
	background-color: #449d44;
	border-color: #398439
}

.btn-success.active.focus,.btn-success.active:focus,.btn-success.active:hover,.btn-success:active.focus,.btn-success:active:focus,.btn-success:active:hover,.open>.dropdown-toggle.btn-success.focus,.open>.dropdown-toggle.btn-success:focus,.open>.dropdown-toggle.btn-success:hover {
	color: #fff;
	background-color: #398439;
	border-color: #255625
}

.btn-success.active,.btn-success:active,.open>.dropdown-toggle.btn-success {
	background-image: none
}

.btn-success.disabled.focus,.btn-success.disabled:focus,.btn-success.disabled:hover,.btn-success[disabled].focus,.btn-success[disabled]:focus,.btn-success[disabled]:hover,fieldset[disabled] .btn-success.focus,fieldset[disabled] .btn-success:focus,fieldset[disabled] .btn-success:hover {
	background-color: #5cb85c;
	border-color: #4cae4c
}

.btn-success .badge {
	color: #5cb85c;
	background-color: #fff
}

.btn-info {
	color: #fff;
	background-color: #5bc0de;
	border-color: #46b8da
}

.btn-info.focus,.btn-info:focus {
	color: #fff;
	background-color: #31b0d5;
	border-color: #1b6d85
}

.btn-info:hover {
	color: #fff;
	background-color: #31b0d5;
	border-color: #269abc
}

.btn-info.active,.btn-info:active,.open>.dropdown-toggle.btn-info {
	color: #fff;
	background-color: #31b0d5;
	border-color: #269abc
}

.btn-info.active.focus,.btn-info.active:focus,.btn-info.active:hover,.btn-info:active.focus,.btn-info:active:focus,.btn-info:active:hover,.open>.dropdown-toggle.btn-info.focus,.open>.dropdown-toggle.btn-info:focus,.open>.dropdown-toggle.btn-info:hover {
	color: #fff;
	background-color: #269abc;
	border-color: #1b6d85
}

.btn-info.active,.btn-info:active,.open>.dropdown-toggle.btn-info {
	background-image: none
}

.btn-info.disabled.focus,.btn-info.disabled:focus,.btn-info.disabled:hover,.btn-info[disabled].focus,.btn-info[disabled]:focus,.btn-info[disabled]:hover,fieldset[disabled] .btn-info.focus,fieldset[disabled] .btn-info:focus,fieldset[disabled] .btn-info:hover {
	background-color: #5bc0de;
	border-color: #46b8da
}

.btn-info .badge {
	color: #5bc0de;
	background-color: #fff
}

.btn-warning {
	color: #fff;
	background-color: #f0ad4e;
	border-color: #eea236
}

.btn-warning.focus,.btn-warning:focus {
	color: #fff;
	background-color: #ec971f;
	border-color: #985f0d
}

.btn-warning:hover {
	color: #fff;
	background-color: #ec971f;
	border-color: #d58512
}

.btn-warning.active,.btn-warning:active,.open>.dropdown-toggle.btn-warning {
	color: #fff;
	background-color: #ec971f;
	border-color: #d58512
}

.btn-warning.active.focus,.btn-warning.active:focus,.btn-warning.active:hover,.btn-warning:active.focus,.btn-warning:active:focus,.btn-warning:active:hover,.open>.dropdown-toggle.btn-warning.focus,.open>.dropdown-toggle.btn-warning:focus,.open>.dropdown-toggle.btn-warning:hover {
	color: #fff;
	background-color: #d58512;
	border-color: #985f0d
}

.btn-warning.active,.btn-warning:active,.open>.dropdown-toggle.btn-warning {
	background-image: none
}

.btn-warning.disabled.focus,.btn-warning.disabled:focus,.btn-warning.disabled:hover,.btn-warning[disabled].focus,.btn-warning[disabled]:focus,.btn-warning[disabled]:hover,fieldset[disabled] .btn-warning.focus,fieldset[disabled] .btn-warning:focus,fieldset[disabled] .btn-warning:hover {
	background-color: #f0ad4e;
	border-color: #eea236
}

.btn-warning .badge {
	color: #f0ad4e;
	background-color: #fff
}

.btn-danger {
	color: #fff;
	background-color: #d9534f;
	border-color: #d43f3a
}

.btn-danger.focus,.btn-danger:focus {
	color: #fff;
	background-color: #c9302c;
	border-color: #761c19
}

.btn-danger:hover {
	color: #fff;
	background-color: #c9302c;
	border-color: #ac2925
}

.btn-danger.active,.btn-danger:active,.open>.dropdown-toggle.btn-danger {
	color: #fff;
	background-color: #c9302c;
	border-color: #ac2925
}

.btn-danger.active.focus,.btn-danger.active:focus,.btn-danger.active:hover,.btn-danger:active.focus,.btn-danger:active:focus,.btn-danger:active:hover,.open>.dropdown-toggle.btn-danger.focus,.open>.dropdown-toggle.btn-danger:focus,.open>.dropdown-toggle.btn-danger:hover {
	color: #fff;
	background-color: #ac2925;
	border-color: #761c19
}

.btn-danger.active,.btn-danger:active,.open>.dropdown-toggle.btn-danger {
	background-image: none
}

.btn-danger.disabled.focus,.btn-danger.disabled:focus,.btn-danger.disabled:hover,.btn-danger[disabled].focus,.btn-danger[disabled]:focus,.btn-danger[disabled]:hover,fieldset[disabled] .btn-danger.focus,fieldset[disabled] .btn-danger:focus,fieldset[disabled] .btn-danger:hover {
	background-color: #d9534f;
	border-color: #d43f3a
}

.btn-danger .badge {
	color: #d9534f;
	background-color: #fff
}

.btn-link {
	font-weight: 400;
	color: #337ab7;
	border-radius: 0
}

.btn-link,.btn-link.active,.btn-link:active,.btn-link[disabled],fieldset[disabled] .btn-link {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none
}

.btn-link,.btn-link:active,.btn-link:focus,.btn-link:hover {
	border-color: transparent
}

.btn-link:focus,.btn-link:hover {
	color: #23527c;
	text-decoration: underline;
	background-color: transparent
}

.btn-link[disabled]:focus,.btn-link[disabled]:hover,fieldset[disabled] .btn-link:focus,fieldset[disabled] .btn-link:hover {
	color: #777;
	text-decoration: none
}

.btn-group-lg>.btn,.btn-lg {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px
}

.btn-group-sm>.btn,.btn-sm {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px
}

.btn-group-xs>.btn,.btn-xs {
	padding: 1px 5px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px
}

.btn-block {
	display: block;
	width: 100%
}

.btn-block+.btn-block {
	margin-top: 5px
}

input[type=button].btn-block,input[type=reset].btn-block,input[type=submit].btn-block {
	width: 100%
}

.fade {
	opacity: 0;
	-webkit-transition: opacity .15s linear;
	-o-transition: opacity .15s linear;
	transition: opacity .15s linear
}

.fade.in {
	opacity: 1
}

.collapse {
	display: none
}

.collapse.in {
	display: block
}

tr.collapse.in {
	display: table-row
}

tbody.collapse.in {
	display: table-row-group
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition-timing-function: ease;
	-o-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-duration: .35s;
	-o-transition-duration: .35s;
	transition-duration: .35s;
	-webkit-transition-property: height,visibility;
	-o-transition-property: height,visibility;
	transition-property: height,visibility
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid\9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent
}

.dropdown,.dropup {
	position: relative
}

.dropdown-toggle:focus {
	outline: 0
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
	box-shadow: 0 6px 12px rgba(0,0,0,.175)
}

.dropdown-menu.pull-right {
	right: 0;
	left: auto
}

.dropdown-menu .divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5
}

.dropdown-menu>li>a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: 400;
	line-height: 1.42857143;
	color: #333;
	white-space: nowrap
}

.dropdown-menu>li>a:focus,.dropdown-menu>li>a:hover {
	color: #262626;
	text-decoration: none;
	background-color: #f5f5f5
}

.dropdown-menu>.active>a,.dropdown-menu>.active>a:focus,.dropdown-menu>.active>a:hover {
	color: #fff;
	text-decoration: none;
	background-color: #337ab7;
	outline: 0
}

.dropdown-menu>.disabled>a,.dropdown-menu>.disabled>a:focus,.dropdown-menu>.disabled>a:hover {
	color: #777
}

.dropdown-menu>.disabled>a:focus,.dropdown-menu>.disabled>a:hover {
	text-decoration: none;
	cursor: not-allowed;
	background-color: transparent;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.open>.dropdown-menu {
	display: block
}

.open>a {
	outline: 0
}

.dropdown-menu-right {
	right: 0;
	left: auto
}

.dropdown-menu-left {
	right: auto;
	left: 0
}

.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: 12px;
	line-height: 1.42857143;
	color: #777;
	white-space: nowrap
}

.dropdown-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 990
}

.pull-right>.dropdown-menu {
	right: 0;
	left: auto
}

.dropup .caret,.navbar-fixed-bottom .dropdown .caret {
	content: "";
	border-top: 0;
	border-bottom: 4px dashed;
	border-bottom: 4px solid\9
}

.dropup .dropdown-menu,.navbar-fixed-bottom .dropdown .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 2px
}

@media (min-width:768px) {
	.navbar-right .dropdown-menu {
		right: 0;
		left: auto
	}

	.navbar-right .dropdown-menu-left {
		right: auto;
		left: 0
	}
}

.btn-group,.btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle
}

.btn-group-vertical>.btn,.btn-group>.btn {
	position: relative;
	float: left
}

.btn-group-vertical>.btn.active,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:hover,.btn-group>.btn.active,.btn-group>.btn:active,.btn-group>.btn:focus,.btn-group>.btn:hover {
	z-index: 2
}

.btn-group .btn+.btn,.btn-group .btn+.btn-group,.btn-group .btn-group+.btn,.btn-group .btn-group+.btn-group {
	margin-left: -1px
}

.btn-toolbar {
	margin-left: -5px
}

.btn-toolbar .btn,.btn-toolbar .btn-group,.btn-toolbar .input-group {
	float: left
}

.btn-toolbar>.btn,.btn-toolbar>.btn-group,.btn-toolbar>.input-group {
	margin-left: 5px
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
	border-radius: 0
}

.btn-group>.btn:first-child {
	margin-left: 0
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.btn-group>.btn:last-child:not(:first-child),.btn-group>.dropdown-toggle:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.btn-group>.btn-group {
	float: left
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
	border-radius: 0
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.btn-group .dropdown-toggle:active,.btn-group.open .dropdown-toggle {
	outline: 0
}

.btn-group>.btn+.dropdown-toggle {
	padding-right: 8px;
	padding-left: 8px
}

.btn-group>.btn-lg+.dropdown-toggle {
	padding-right: 12px;
	padding-left: 12px
}

.btn-group.open .dropdown-toggle {
	-webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
	box-shadow: inset 0 3px 5px rgba(0,0,0,.125)
}

.btn-group.open .dropdown-toggle.btn-link {
	-webkit-box-shadow: none;
	box-shadow: none
}

.btn .caret {
	margin-left: 0
}

.btn-lg .caret {
	border-width: 5px 5px 0;
	border-bottom-width: 0
}

.dropup .btn-lg .caret {
	border-width: 0 5px 5px
}

h1.heading1 {
    margin: 0 0 10px 0;
    padding: 5px 0;
    border-bottom: 2px solid #1152A2;
    color: #444;
    font-weight: bold;
    font-size: 1.8em;
    font-family: "Hiragino Kaku Gothic Pro";
}

h2.heading2 {
    margin: 0 0 10px 0;
    padding: 15px 8px 2px 30px;
    font-weight: bold;
    font-size: 1.2em;
    background: url(../../components/h2demob1.jpg) no-repeat left;
    clear: both;
    height: 42px;
}

h3.heading3 {
    font-weight: bold;
    font-size: 1em;
    margin: 0 0 10px 0;
    padding: 5px 0 10px 32px;
    height: 25px;
    background: url(../../components/h3.gif)no-repeat left top;
    clear: both;
}
